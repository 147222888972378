<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.2 99.2">
        <path style="stroke-width:0;fill:#ef383f" d="M0 0h99.2v99.2H0z"/>
        <path d="M46.6 15.1H15.9v11.2h30.7v56.3h11.5V43.7c0-4 3.4-7.2 7.7-7.2H83V26.3H46.6V15.1Z" style="fill:#fff;stroke-width:0"/>
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
